<template>
  <div class="d-flex justify-center box-table">
    <!-- Receita -->

    <div>
      <apexchart 
        width="350"
        height="350"
        type="donut"
        :options="graficoDonutR.chartOptions"
        :series="graficoDonutR.series"
        v-if="graficoDonutR.series.length"
      />

    </div>


<!-- custeio -->
  <div class="box-grafico">
    <apexchart
      width="350"
      height="350"
      type="donut"
      :options="graficoColuna.chartOptions"
      :series="graficoColuna.series"
      v-if="graficoColuna.series.length"
    />

    <span v-if="graficoColuna.series.length">{{ getPorcentage( graficoDonutR.series, graficoColuna.series ) | percentage}} </span>

  </div>

    <!-- Despesa -->
  <div class="box-grafico">
    <apexchart
      width="350"
      height="350"
      type="donut"
      :options="graficoDonutD.chartOptions"
      :series="graficoDonutD.series"
      v-if="graficoDonutD.series.length"
    />

    <span v-if="graficoDonutD.series.length">{{ getPorcentage( graficoDonutR.series, graficoDonutD.series ) | percentage}} </span>
  </div>
    
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts'

export default {
  name: "OrganizzeRelatorioCategoriasGraficoPizza",
  components: {
    apexchart
  },

  props: {
    custos: {
      type: Boolean,
      default: false
    },
    graficoDonutD: {
      type: Object,
      required: true
    },

    graficoDonutR: {
      type: Object,
      required: true
    },
    graficoColuna: {
      type: Object,
      required: true
    }
  },

  methods: {
    getPorcentage (receitas, despesas) {
       return (Math.abs(despesas.reduce((a, b) => a + b, 0)) / Math.abs(receitas.reduce((a, b) => a + b, 0))) * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.box-grafico {
  position: relative;

  > span {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>